const sizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
  "heading-S": 'text-[28px]',
  "heading-XL": 'text-4xl'
}

const weights = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold'
}

const colors = {
  white: 'text-white',
  base: 'text-Text-base',
  primary: 'text-Text-primary',
  secondary: 'text-Text-secondary',
  label: 'text-Text-label',
  "light-blue": 'text-color-lightBlue-25',
  "homepage-title": 'text-color-warning-1000',
  "homepage-title-secondary": 'text-color-primary-600',
  price: 'text-color-primary-700',
  gray: 'text-gray-800'
}

export const Typography = ({
    text, 
    weight = 'normal', 
    size = 'sm',
    color = 'base',
    className = "",
}) => {
    const style = [ sizes[size], weights[weight], colors[color], className].join(" ");

    return (
        <span className={style}>{text}</span>
    );
};