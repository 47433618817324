import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { useMutation } from "react-query"
import { postLogin } from "../../../api/auth";
import { toastError, toastSuccess } from "../../atoms/CustomToast";
import { store } from "../../../redux/store";
import { loggedIn } from "../../../redux/logged/loggedAction";
import { loginModalHide } from "../../../redux/loginModal.js/loginModalAction";
import { Typography } from "../../atoms/Typography";
import { InputForm } from "../../molecules/InputForm";
import Button from "../../atoms/Button";
// import { ColorGoogleIcon } from "../../../assets/icons";
import { setToken } from "../../../api/config";
import { useSelector } from "react-redux";

const LoginContent = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const changeStep = (val) => setStep(val)
  const loginModal = useSelector(state => state.loginModal)

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const firstSubmit = data => {
    setEmail(data?.login_email)
    changeStep(2)
  }

  useEffect(() => {
    if (!loginModal) {
      setStep(1)
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginModal])


  const handlePostLogin = useMutation({
    mutationFn: (dataSend) => postLogin(dataSend),
    onSuccess: (datas) => {
      toastSuccess('Login berhasil')
      setStep(1)
      reset()
      setToken(datas.access_token, datas.refresh_token);
      localStorage.setItem('user', JSON.stringify(datas.user))
      store.dispatch(loggedIn())
      store.dispatch(loginModalHide())
    },
    onError: (data) => {
      const errorMessage = data?.response?.data?.errors[0]?.message
      toastError(errorMessage)
    }
  })

  const secondSubmit = data => {
    const dataSend = {
      email: email,
      password: data.login_password
    }
    handlePostLogin.mutate(dataSend)
  }

  return (
    <div className="space-y-8">
      <div className="justify-center text-center flex flex-col">
        <Typography
          color="base"
          size="heading-S"
          text={'Masuk'}
          weight="bold"
        />
        <Typography
          color="secondary"
          size="sm"
          text="Silahkan masukan data Anda"
        />
      </div>
      <div className="space-y-4">
        {
          step === 1 ? (
            <form className="space-y-4" onSubmit={handleSubmit(firstSubmit)}>
              <InputForm
                type="text"
                label=""
                placeholder="Masukan Email"
                register={register}
                name={'login_email'}
                errorMessage={errors?.login_email?.message}
                validationRules={{
                  required: "Email tidak boleh kosong",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Format Email tidak valid',
                  },
                }}
              />
              <Button
                type="submit"
                intent="primary"
                color="primary"
                size="sm"
                onClick={handleSubmit}
                className="w-full"
              >
                Selanjutnya
              </Button>
            </form>
          ) : (
            <form className="space-y-4" onSubmit={handleSubmit(secondSubmit)}>
              <div>
                <label className="block">
                  <span className="text-xs-normal text-whitealpha-700">Email</span>
                </label>
                <div className="flex justify-between items-center">
                  <Typography
                    size="sm"
                    weight="bold"
                    color="base"
                    text={email}
                  />
                  <button
                    className="border-Base-Inline text-Text-primary"
                    onClick={() => changeStep(1)}
                  >
                    Ubah
                  </button>
                </div>
              </div>
              <InputForm
                type="password"
                label=""
                placeholder="Masukan Password"
                name="login_password"
                register={register}
                errorMessage={errors?.login_password?.message}
                validationRules={{
                  required: "Password tidak boleh kosong",
                }}
              />
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="h-5 w-5 accent-color-primary-500"
                  // checked={isAgreee}
                  // onChange={e => setIsAgree(e.target.checked)}
                  />
                  <Typography
                    size="md"
                    weight="regular"
                    color="xs"
                    text="Ingat saya"
                  />
                </div>
                <button
                  type="button"
                  className="text-color-primary-500"
                  onClick={() => {
                    navigate('/forgot-password')
                    store.dispatch(loginModalHide())
                  }}>
                  Lupa Kata Sandi
                </button>
              </div>
              <Button
                type="submit"
                intent="primary"
                color="primary"
                size="sm"
                onClick={handleSubmit}
                className="w-full"
              >
                {'Masuk'}
              </Button>
            </form>
          )
        }
      </div>
      {/* <div className="space-y-4">
        <div className='font-sm text-Text-secondary text-between-lines text-center'>atau masuk dengan</div>
        <Button
          type="button"
          intent="outline"
          color="gray"
          size="sm"
          className="w-full"
        >
          <div className="flex items-center justify-center space-x-3">
            <ColorGoogleIcon />
            <Typography
              size="md"
              weight="semibold"
              color="base"
              text='Google'
            />
          </div>
        </Button>
      </div> */}
      <div className="text-center">
        <Typography
          size="sm"
          color="secondary"
          text={<>
            disini belum punya akun?
            {' '}
            <button
              className="border-Base-Inline font-semibold text-Text-primary ml-1"
              onClick={() => navigate('/register')}
            >
              Daftar
            </button>
          </>}
        />
      </div>
    </div>
  )
}

export default LoginContent