import { useEffect, useState } from "react";
import { ApplicationIcon, ArrowDropdownFillIcon } from "../../assets/icons";
import HeaderDropdown from "../atoms/HeaderDropdown";
import { Typography } from "../atoms/Typography";
import { useQuery } from "react-query";
import { getAuctionUnitType } from "../../api/global";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { setProductType } from "../../redux/productType/productTypeAction";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../utils";

const HeaderCategory = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const productType = useSelector((state) => state.productType);

  useQuery({
    queryKey: "getAuctionUnitType",
    queryFn: () => getAuctionUnitType(),
    onSuccess: (data) => {
      store.dispatch(
        setProductType(
          data.product_types.map((v) => ({
            ...v,
            label: v.name,
            value: v.id,
            product_tags: v.product_tags.map((item) => ({
              ...item,
              label: item.tag_name,
              value: item.id
            }))
          }))
        )
      );
    },
  });

  const onProductTypeClicked = (data) => {
    setSubCategories(data.product_tags);

    if (!data.product_tags.length) {
      navigate(`/schedule-catalogue?product_type_id=${encrypt(data.id)}`);
      setOpen(false);
    }
  };

  const onSubCategoryClicked = (data) => {
    navigate(
      `/schedule-catalogue?product_type_id=${encrypt(
        data.product_type_id
      )}&product_tag_ids=${encrypt(data.id)}`
    );

    setOpen(false);
  };

  useEffect(() => {
    setSubCategories([]);
  }, [open]);

  return (
    <HeaderDropdown
      open={open}
      setOpen={setOpen}
      button={
        <div className="flex items-center space-x-1 text-color-neutral-500">
          <ApplicationIcon />
          <Typography text="Kategori" size="lg" color="secondary" />
          <ArrowDropdownFillIcon />
        </div>
      }
    >
      <div className="w-[580px] p-5 grid grid-cols-12">
        <div
          className={`${
            subCategories.length
              ? "col-span-4 border-r border-color-neutral-200 pr-5"
              : "col-span-12"
          }`}
        >
          {productType.map((type, index) => (
            <button
              key={`header-catetgory-${index + 1}`}
              className="py-2 px-4 hover:bg-color-neutral-100 rounded-md w-full text-left"
              onClick={() => onProductTypeClicked(type)}
            >
              <Typography
                text={type.name}
                size="md"
                weight="semibold"
                color="secondary"
              />
            </button>
          ))}
        </div>
        {subCategories.length ? (
          <div className="col-span-8 pl-5 h-[360px] overflow-y-auto">
            {subCategories.map((sub, idx) => (
              <button
                key={`header-sub-catetgory-${idx + 1}`}
                className="py-2 px-4 hover:bg-color-neutral-100 rounded-md w-full text-left"
                onClick={() => onSubCategoryClicked(sub)}
              >
                <Typography text={sub.tag_name} size="md" color="base" />
              </button>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </HeaderDropdown>
  );
};

export default HeaderCategory;
