import { useNavigate } from "react-router-dom"
import { BellIcon } from "../../assets/icons"
import HeaderDropdown from "../atoms/HeaderDropdown"
import NotificationItem from "../atoms/NotificationItem"
import { Typography } from "../atoms/Typography"
import { useState } from "react"
import { getNotificationList, getNotificationReadAll } from "../../api/profileNotication"
import { useQuery } from "react-query"

const HeaderNotification = ({ position }) => {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useQuery({
    queryKey: 'getNotificationList',
    queryFn: () => getNotificationList({ limit: 5, page: 1 }),
    onSuccess: (data) => {
      setNotifications(data.data.user_notifications)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

  const clickAll = useQuery({
    queryKey: 'getNotificationReadAll',
    queryFn: () => getNotificationReadAll(),
    onSuccess: (data) => {
    },
    staleTime: Infinity,
    enabled: false
  })

  const handleClickReadAll = () => {
    clickAll.refetch()
  }

  return (
    <HeaderDropdown
      position={position}
      open={isOpen}
      setOpen={setIsOpen}
      button={
        <div className="flex relative items-center space-x-1 text-color-neutral-500">
          {
            notifications.length > 0 &&
            <div className="rounded-full z-[1] right-[-5px] top-[-8px] absolute bg-Button-danger-Primary h-5 w-5 text-xs flex items-center justify-center text-white">
              {notifications.length}
            </div>
          }
          <BellIcon />
        </div>
      }
    >
      <div className="w-[339px]">
        <div className="py-[10px] px-5 flex justify-between items-center border-b border-color-neutral-200" >
          <Typography
            text="Notifikasi"
            size="base"
            weight="semibold"
            color="base"
          />
          <button
            className="text-Text-primary text-sm"
            type="button"
            onClick={handleClickReadAll}
          >
            Tandai Semua Dibaca
          </button>
        </div>
        <div className="h-[288px] overflow-y-auto">
          {
            notifications.length > 0 ?
              notifications.map((el, index) => (
                <NotificationItem key={`notification-header-${index + 1}`} item={el} />
              )) :
              <div className='h-full w-full flex items-center justify-center'>
                <Typography
                  text="Tidak ada Notifikasi"
                  size="sm"
                  color="secondary"
                />
              </div>
          }
        </div>
        <div className="text-center border-t border-color-neutral-200 py-[10px]">
          <button className="text-Text-primary text-sm" onClick={() => {
            navigate('/profile/notification')
            setIsOpen(false)
          }}>
            Lihat Selengkapnya
          </button>
        </div>
      </div>

    </HeaderDropdown>
  )
}

export default HeaderNotification