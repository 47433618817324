import { combineReducers } from 'redux';
import { loggedReducer } from './logged/loggedReducer';
import { loginModalReducer } from './loginModal.js/loginModalReducer';
import { productTypeReducer } from "./productType/productTypeReducer";

const rootReducer = combineReducers({
    logged: loggedReducer,
    loginModal: loginModalReducer,
    productType: productTypeReducer,
});

export default rootReducer;