import Input from "../atoms/Input";

export const InputForm = ({
  label = "Input Label",
  placeholder = "",
  id = "",
  name = "",
  type = "text",
  errorMessage,
  className = 'mx-0.5',
  register,
  validationRules
}) => {

  return (
    <div className={`space-y-[6px] ${className}`}>
      <label htmlFor={id} className="block mb-2">
        <span className="text-sm font-normal text-whitealpha-700">{label}</span>
      </label>
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        className={className}
        // {...register(name, validationRules)}
        {...(register && validationRules ? register(name, validationRules) : {})}
      />

      { errorMessage && errorMessage.length > 0 &&
        <p className="text-sm text-color-danger-500 mt-2">{errorMessage}</p> }
    </div>

  );
}