import { axiosGlobal } from "./config";

const getAuctionUnitType = async () => {
  const { data: response } = await axiosGlobal.get(
    `/tipe-produk`
  )
  return response.data
}
const getAuctionBranch = async () => {
  const { data: response } = await axiosGlobal.get(
    `/branch-location`
  )
  return response.data
}

const getProductUnitTypeAll = async () => {
  const { data: response } = await axiosGlobal.get(
    `/tipe-produk-all`
  )
  return response.data
}

const getProcedures = async () => {
  const { data: response } = await axiosGlobal.get(
    `/kategori-prosedur`, { params: { limit: 10, page: 1 } }
  )
  return response.data
}
const getPoolLocations = async (params) => {
  const { data: response } = await axiosGlobal.get(
    `/pool-location`, { params }
  )
  return response.data
}

export {
  getAuctionUnitType,
  getProductUnitTypeAll,
  getAuctionBranch,
  getProcedures,
  getPoolLocations
}