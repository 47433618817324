import axios from 'axios'
import { store } from '../../redux/store';
import { loggedOut } from '../../redux/logged/loggedAction';
import { toastError } from '../../components/atoms/CustomToast';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const axiosGlobal = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    "Accept": 'application/json',
    // Add any other default headers for API 1
  },
});

axiosGlobal.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const setToken = (token, refresh_token) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refresh_token', refresh_token);
  axiosGlobal.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

let isError = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

// Request interceptor for API 1
axiosGlobal.interceptors.request.use(
  (config) => {
    // Modify request config for API 1 (e.g., add authorization token)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosGlobal.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {

      if (isError) {
        try {
          const token = await new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return await axios(originalRequest);
        } catch (err) {
          return await Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isError = true;

      const refreshToken = localStorage.getItem('refresh_token');
      return new Promise(function (resolve, reject) {
        axios.post(`${BASE_URL}/auth/renew-token`, {
          refresh_token: refreshToken
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(({ data }) => {
            localStorage.setItem('token', data.data.access_token);
            localStorage.setItem('refresh_token', data.data.refresh_token);
            axiosGlobal.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.data.access_token;

            processQueue(null, data.data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            toastError('Sesi telah berakhir')
            store.dispatch(loggedOut())
            localStorage.removeItem('token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('user')

            processQueue(err, null);
            reject(err);
          })
          .finally(() => { isError = false })
      })
    }

    return Promise.reject(error);
  }
);